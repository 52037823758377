import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Block } from './Block'

interface Props {
  children: ReactNode
}

const FAQWrapper = ({ children }: Props) => {
  return (
    <FAQContainer>
      <Block size="medium" type="padding-large">
        <h6>Frequently asked questions</h6>
        <div className="faq-container text-tight">{children}</div>
      </Block>
    </FAQContainer>
  )
}

export default FAQWrapper

const FAQContainer = styled.div`
  background: #f6f6f6;

  h6 {
    text-align: center;
  }

  //section styling
  details {
    padding: 0 40px 0;
    border-radius: 12px;
    background: white;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.06),
      0px 3px 2px 0px rgba(0, 0, 0, 0.02);
    margin-bottom: 8px;

    summary {
      list-style: none;
      font-weight: 600;
      padding: 24px 32px 24px 0;
      line-height: 1.5rem;
      position: relative;
      cursor: pointer;
    }
  }

  details[open] {
    padding-bottom: 32px;
    box-shadow: 0px 17px 38px 0px rgba(23, 23, 26, 0.05),
      0px 13px 12px 0px rgba(0, 0, 0, 0.05);

    summary {
      padding-bottom: 16px;
    }
  }

  //marker styling

  summary::-webkit-details-marker {
    display: none;
  }

  details > summary:after {
    content: '+';
    position: absolute;
    font-weight: 400;
    color: #1a66d8;
    right: 0px;
    top: 22px;
    display: inline-block;
    font-size: 32px;
    height: 32px;
    width: 24px;
    text-align: center;
  }

  details[open] > summary:after {
    content: '–';
  }
`
