import { useLocation } from '@gatsbyjs/reach-router'
import React, { useEffect, useState } from 'react'
import infoIcon from '../assets/images/info-icon.svg'
import G2AndCustomerLogos from '../components/G2AndCustomerLogos'
import Layout from '../components/Layout'
import FAQ from '../components/ROIFAQ'
import ToggleSelector from '../components/ToggleSelector'
import Tooltip from '../components/Tooltip'
import CTABlock from '../components/blocks/CTABlock'
import { gridSpacing } from '../components/blocks/ColumnsBlock'
import { HeroBlockPlain } from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import Block from '../components/primitives/Block'
import Box from '../components/primitives/Box'
import CardFrame from '../components/primitives/CardFrame'
import Row from '../components/primitives/Row'
import Stack from '../components/primitives/Stack'
import Br from '../components/system/Br'
import Button from '../components/system/Button'
import { useCurrency } from '../regionUtils'
import { responsiveScale } from '../styles/helpers'
import { SpaceScaleValue } from '../styles/stylePropTypes'
import theme from '../styles/theme'

const pricingTiers = [
  { title: 'Lite', price: 20 },
  { title: 'Standard', price: 39 },
]

const numberOfDevelopersPerManager = 5

const ROIPage = () => {
  const currency = useCurrency()
  const [selectedTier, setSelectedTier] = useState(pricingTiers[1])

  const planMultiplier = selectedTier.title === 'Lite' ? 0.25 : 1

  const features = [
    {
      id: 'notifications',
      label: 'Notifications',
      tooltip:
        'Most organizations see a 20-50% reduction in pull request review times after adopting Swarmia’s Slack notifications. With them, developers spend less time on chasing reviews and jumping between pull requests.',
      estimate: 'Conservative estimate: 10 minutes saved per developer per day',
      savingsHoursPerDay: 0.16,
    },
    {
      id: 'workingAgreements',
      label: 'Working Agreements',
      tooltip:
        'Automated working agreements allow teams to drive change without manual reporting or data gathering.',
      estimate: 'Conservative estimate: 2 hours saved per team lead per week',
      savingsHoursPerDay: 2 / 5 / numberOfDevelopersPerManager,
    },
    {
      id: 'doraMetrics',
      label: 'DORA metrics',
      tooltip:
        'Adopting DORA metrics leads to less time spent on manual releases and fixing change failures.',
      estimate: 'Conservative estimate: 2 hours saved per team per week',
      savingsHoursPerDay: 2 / 5 / numberOfDevelopersPerManager,
    },
    {
      id: 'ciInsights',
      label: 'CI Insights',
      tooltip:
        'CI insights allow teams to find areas of improvement and address wait times before they cause substantial waste.',
      estimate:
        'Conservative estimate: 10 minutes wait time reduced per developer per day',
      savingsHoursPerDay: 0.16,
    },
    {
      id: 'workLog',
      label: 'Work Log',
      tooltip:
        'The work log allows teams to quickly eliminate common teamwork antipatterns and run better retrospectives.',
      estimate: `Conservative estimate: ${
        20 * planMultiplier
      } minutes saved per developer per week and ${
        60 * planMultiplier
      } minutes saved per team lead per week`,
      savingsHoursPerDay:
        ((0.33 + 1 / numberOfDevelopersPerManager) / 5) * planMultiplier,
    },
    {
      id: 'issueInsights',
      label: 'Issue Insights',
      tooltip:
        'The Jira and Linear insights in Swarmia help your teams improve their flow efficiency. This leads to reduced context switching and added visibility into work in progress. Most organizations see 25% to 80% improvements in flow efficiency after a few months of using Swarmia.',
      estimate: 'Conservative estimate: 20 minutes saved per developer per day',
      savingsHoursPerDay: 0.33,
      notInLite: true,
    },
    {
      id: 'initiatives',
      label: 'Initiatives',
      tooltip:
        'Tracking engineering initiatives with Swarmia allows your teams to focus on the right things.',
      estimate:
        'Conservative estimate: 5% more time spent on important objectives',
      savingsHoursPerDay: 8 * 0.05,
      notInLite: true,
    },
  ]

  const [currentData, setCurrentData] = useState(() => ({
    developerCost: '100000',
    discountPercentage: '0',
    numberOfDevelopers: '10',
    ...features.reduce((acc, feature) => ({ ...acc, [feature.id]: 'on' }), {}),
  }))

  const [discountCode, setDiscountCode] = useState<string | null>(null)

  // We need to access URL params through `useEffect` to avoid hydration errors :/
  const location = useLocation()
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const discountCode = searchParams.get('code')
    if (discountCode) {
      setDiscountCode(discountCode)
      setCurrentData({
        ...currentData,
        discountPercentage: '50',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we need to do this only once
  }, [])

  const monthlySwarmiaCost =
    selectedTier.price *
    ((100 - Number(currentData.discountPercentage ?? 0)) / 100)

  const dailyDeveloperCost = Number(currentData.developerCost) / 240
  const hourlyDeveloperCost = dailyDeveloperCost / 8

  const savingsHoursPerDay = features.reduce(
    (acc, feature) =>
      currentData[feature.id] === 'on' &&
      (!feature.notInLite || selectedTier.title === 'Standard')
        ? acc + feature.savingsHoursPerDay
        : acc,
    0,
  )
  const savingsHoursPerMonth = savingsHoursPerDay * 20

  const annualCost = 12 * monthlySwarmiaCost
  const annualCostInDeveloperHours = annualCost / hourlyDeveloperCost
  const annualPaybackDays = annualCostInDeveloperHours / savingsHoursPerDay

  const developerSavingsPerMonth = savingsHoursPerMonth * hourlyDeveloperCost
  const roiX = developerSavingsPerMonth / monthlySwarmiaCost
  const annualProductivityGains =
    savingsHoursPerMonth * 12 * Number(currentData.numberOfDevelopers)

  const onChange = event => {
    const formData = new FormData(event.currentTarget)
    const data = Object.fromEntries(formData.entries())

    if (
      'checked' in event.target &&
      features.every(feature => data[feature.id] !== 'on')
    ) {
      event.target.setCustomValidity('Select at least one feature')
      event.target.reportValidity()
      event.target.checked = true
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setCurrentData(data as any)
  }
  const onSubmit = event => {
    event.preventDefault()
    onChange(event)
  }

  const featuresPerColumn = Math.ceil(features.length / 2)

  return (
    <Layout
      title="Build your business case"
      variant="light"
      isNew
      description="See how fast your investment in Swarmia will pay off. Quickly calculate your return on investment based on the features you’re using."
      noIndex
    >
      <HeroBlockPlain
        title="Build your business case"
        content="See how fast your investment in Swarmia will pay off. Calculate your return on investment based on the features you’re using."
        customCta={<></> /* empty custom CTA hides the buttons */}
      />
      <Block
        paddingTop={responsiveScale(32)}
        paddingBottom={responsiveScale(92)}
        maxWidth={1200}
      >
        <Row space={gridSpacing} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box flex={1} zIndex={1}>
            <CardFrame
              width="100%"
              height="100%"
              paddingX={{ xs: 24, xsm: 32 }}
              paddingY={32}
              overflow="visible"
            >
              <Box.form onSubmit={onSubmit} onChange={onChange}>
                <Stack space={48} textAlign="left">
                  <Box>
                    <Stack space={24}>
                      <Row
                        space={{ xs: 24, xsm: 32 }}
                        flexDirection={{ xs: 'column', sm: 'row' }}
                      >
                        <Box flex={1}>
                          <Label id="tier" label="Selected plan" tooltip="" />
                          <Box maxWidth={220}>
                            <ToggleSelector
                              options={pricingTiers}
                              selectedOption={selectedTier}
                              onChange={setSelectedTier}
                              variant="dark"
                            />
                          </Box>
                        </Box>
                        <Input
                          id="numberOfDevelopers"
                          label="Number of developers"
                          defaultValue={10}
                          maxWidth={80}
                          max={undefined}
                          tooltip=""
                        />
                      </Row>
                      <Row
                        space={{ xs: 24, xsm: 32 }}
                        flexDirection={{ xs: 'column', sm: 'row' }}
                      >
                        <Input
                          id="developerCost"
                          label="Average engineer salary"
                          suffix={currency === 'EUR' ? '€' : '$'}
                          defaultValue={100000}
                          max={undefined}
                          tooltip="The fully loaded annual cost of a single developer including bonuses, benefits, insurance, and administrative overhead."
                        />
                        {discountCode ? (
                          <Input
                            id="discountPercentage"
                            label="Discount percentage"
                            suffix="%"
                            defaultValue={50}
                            step={25}
                            min={0}
                            max={50}
                            maxWidth={75}
                            tooltip={
                              <>
                                Automatically included the discount from your
                                coupon code <strong>{discountCode}</strong>
                              </>
                            }
                          />
                        ) : null}
                      </Row>
                    </Stack>
                  </Box>
                  <Box>
                    <Box font="h4" paddingBottom={16}>
                      Features we’re using:
                    </Box>
                    <Row
                      space={{ xs: 0, xsm: 8 }}
                      flexDirection={{ xs: 'column', sm: 'row' }}
                    >
                      <FeatureStack
                        features={features.slice(0, featuresPerColumn)}
                        selectedTier={selectedTier}
                      />
                      <FeatureStack
                        features={features.slice(featuresPerColumn)}
                        selectedTier={selectedTier}
                      />
                    </Row>
                  </Box>
                  <Row space={16}>
                    <Button size="large" variant="primary" type="submit">
                      Calculate
                    </Button>
                    <Button size="large" type="reset">
                      Clear
                    </Button>
                  </Row>
                </Stack>
              </Box.form>
            </CardFrame>
          </Box>
          <Box width={{ xs: '100%', md: 400 }} zIndex={0}>
            <CardFrame
              width="100%"
              height="100%"
              paddingX={{ xs: 24, xsm: 32 }}
              paddingY={32}
            >
              <Stack space={48} textAlign="center">
                <RoiInfo
                  title="Time saved"
                  value={`${Math.round(annualProductivityGains)} hours`}
                  description="The number of developer hours Swarmia saves you in a year"
                />
                <RoiInfo
                  title="Return on investment"
                  value={`${Math.round(roiX)}x`}
                  description="The number of times you get your investment in Swarmia back"
                />
                <RoiInfo
                  title="Payback"
                  value={`${Math.round(annualPaybackDays)} days`}
                  description="The time it takes for an annual investment in Swarmia to start paying off"
                />
              </Stack>
            </CardFrame>
          </Box>
        </Row>
      </Block>

      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <FAQ />
      </Box>
      <LeadBlock
        heading={
          <>
            Trusted by effective <Br />
            engineering organizations
          </>
        }
        link={{ title: 'Read customer stories', href: '/customers/' }}
      />
      <Box paddingTop={responsiveScale(32)} paddingBottom={responsiveScale(92)}>
        <G2AndCustomerLogos hideTitle />
      </Box>
      <CTABlock />
    </Layout>
  )
}

export default ROIPage

function Input({
  id,
  label,
  suffix = '',
  defaultValue,
  step = 1,
  max,
  min = 1,
  tooltip,
  maxWidth = 200,
}) {
  return (
    <Box flex={1}>
      <Label id={id} label={label} tooltip={tooltip} />
      <Box display="flex" alignItems="center">
        <Box.input
          flex={1}
          id={id}
          name={id}
          type="number"
          min={min}
          max={max}
          defaultValue={defaultValue}
          step={step}
          width="100%"
          maxWidth={maxWidth}
          font="h5"
          textAlign="right"
          borderColor="black200"
          borderRadius={12}
          paddingRight={{ xs: 12, sm: 0 }}
          paddingLeft={12}
          paddingY={12}
        />
        <Box font="h5" paddingLeft={8}>
          {suffix}
        </Box>
      </Box>
    </Box>
  )
}

function Label({ id, label, tooltip = '' }) {
  return (
    <Box.label
      display="block"
      font="h5"
      fontWeight={500}
      htmlFor={id}
      paddingBottom={12}
    >
      {label} {tooltip ? <InfoTooltip tooltip={tooltip} /> : null}
    </Box.label>
  )
}

function Checkbox({ id, label, tooltip, estimate, disabled = false }) {
  return (
    <Box>
      <Box.input
        type="checkbox"
        id={id}
        name={id}
        disabled={disabled}
        css={`
          position: relative;
          cursor: pointer;
          width: 24px;
          height: 24px;
          top: 4px;

          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 24px;
            height: 24px;
            border-radius: 3px;
            top: 0;
            left: 0;
            box-shadow: 0px 2px 1px 0px #0000000f inset;
            box-shadow: 0px 3px 2px 0px #00000005 inset;
            background: white;
            border: 1px solid #c6cceb;
          }
          &:checked:before {
            content: '';
            display: block;
            position: absolute;
            width: 24px;
            height: 24px;
            top: 0;
            left: 0;
            background-color: #1e80ef;
            border: none;
          }
          &:checked:after {
            content: '';
            display: block;
            width: 8px;
            height: 14px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 4px;
            left: 8px;
          }
          &:disabled:after {
            content: none;
          }
          &:disabled:before {
            border: 1px solid ${theme.colors.black200};
            background-color: ${theme.colors.black100};
          }
        `}
        defaultChecked
      />
      <Box.label
        paddingLeft={8}
        font="h5"
        htmlFor={id}
        fontWeight={500}
        color={disabled ? 'black600' : 'black'}
      >
        {label}{' '}
        <InfoTooltip
          tooltip={
            <Stack space={12}>
              <Box>{tooltip}</Box>
              {disabled ? (
                <Box>Not included in the Lite plan.</Box>
              ) : (
                <Box>{estimate}</Box>
              )}
            </Stack>
          }
        />
      </Box.label>
    </Box>
  )
}

function FeatureStack({ features, selectedTier }) {
  return (
    <Stack space={8} flex={1}>
      {features.map(feature => (
        <Checkbox
          key={feature.id}
          id={feature.id}
          label={feature.label}
          tooltip={feature.tooltip}
          estimate={feature.estimate}
          disabled={feature.notInLite && selectedTier.title === 'Lite'}
        />
      ))}
    </Stack>
  )
}

function InfoTooltip({ tooltip }) {
  return (
    <Box.span
      display="inline-block"
      position="relative"
      marginLeft={-12 as SpaceScaleValue}
      top={4}
    >
      <Tooltip
        title={tooltip}
        font="small"
        width={{ xs: 240, md: 400 }}
        tooltipTransform={{ xs: 'translateX(-80%)', md: 'translateX(-50%)' }}
      >
        <Box.img src={infoIcon} width={20} />
      </Tooltip>
    </Box.span>
  )
}

function RoiInfo({ title, value, description }) {
  return (
    <Stack flex={1} space={16} alignItems="center">
      <Box font="textLabel" textTransform="uppercase">
        {title}
      </Box>
      <Box font="h1">{value}</Box>
      <Box font="small" maxWidth={300}>
        {description}
      </Box>
    </Stack>
  )
}
